<template>
	<div class="gg-container">
		<el-button type="danger" @click="closeDrawer"
							 style="position: absolute; display: flex; justify-content: center; align-items: center; border: 0; border-radius: 4px 0 0 4px; top: 140px; left: -40px;width: 40px; height: 40px;">
			<i class="iconfont icon-cha" style="font-size: 24px"></i>
		</el-button>
		<div class="caption-container">
			<p class="caption-title">患者信息</p>
			<div class="caption-wrapper">
				<div class="caption-avatar">
					<el-avatar :size="60" :src="circleUrl"></el-avatar>
				</div>
				<div class="caption-info">
					<div class="caption-info-up">
						<span class="name">测试名称</span>
						<span class="mobile">测试电话</span>
					</div>
					<div class="caption-info-down">
						<div class="gender">
							<div class="nv">
								<i class="iconfont icon-nv"></i>
							</div>
						</div>
						<div class="intro">
							<i class="iconfont icon-yishichangjing intro-icon"></i>
							<span class="intro-title">数据</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="gg-tabs">
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="用户管理" name="first">
					<div class="required"></div><span>信息1</span>
					<el-row :gutter="20">
						<el-col :span="6"><div class="grid-content bg-purple"></div></el-col>
						<el-col :span="6"><div class="grid-content bg-purple"></div></el-col>
						<el-col :span="6"><div class="grid-content bg-purple"></div></el-col>
						<el-col :span="6"><div class="grid-content bg-purple"></div></el-col>
					</el-row>
					<div class="required"></div><span>图片上传</span>
					<el-row :gutter="20">
						<el-col :span="6"><div class="grid-content bg-purple"></div></el-col>
						<el-col :span="6"><div class="grid-content bg-purple"></div></el-col>
						<el-col :span="6"><div class="grid-content bg-purple"></div></el-col>
						<el-col :span="6"><div class="grid-content bg-purple"></div></el-col>
					</el-row>
				</el-tab-pane>
				<el-tab-pane label="配置管理" name="second">配置管理</el-tab-pane>
				<el-tab-pane label="角色管理" name="third">角色管理</el-tab-pane>
				<el-tab-pane label="定时任务补偿" name="fourth">定时任务补偿</el-tab-pane>
			</el-tabs>
		</div>
	
	</div>
</template>

<script>
export default {
  name: "DrawTest",
  props: {
    info: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      circleUrl: require('@/assets/avatar.png'),
      activeName: 'first'
    }
  },
  methods: {
    // 关闭抽屉出发上一个页面数据
    closeDrawer () {
      console.log(123)
      this.$emit('closeDrawer', false)
    },
    // tabs交换
    handleClick(tab, event) {
      console.log(tab, event);
    }
  }
}
</script>

<style scoped lang="scss">
	/*竖杠+后面文本的样式*/
	.required {
		margin-top: 3px;
		display: inline-block;
		width: 4px;
		height: 14px;
		background-color: #2362FB;
		top: 20px;
	}
	
	.required + span {
		display: inline-block;
		margin-left: 4px;
		vertical-align: top;
		font-weight: bold;
		font-size: 14px;
	}
	
	/*caption-container样式*/
	.caption-container {
		.caption-title {
			font-size: 16px;
			color: #111;
			font-weight: bold;
			margin-top: 0;
		}
		
		.caption-wrapper {
			display: flex;
			justify-content: left;
			align-items: center;
			
			.caption-avatar {
			}
			
			.caption-info {
				display: flex;
				flex-direction: column;
				margin-left: 20px;
				
				.caption-info-up {
					height: 30px;
					font-size: 16px;
					font-weight: bold;
					color: #111;
					
					.name {
						margin-right: 15px;
					}
				}
				
				.caption-info-down {
					display: flex;
					justify-content: left;
					align-items: center;
					height: 25px;
					
					.gender {
						width: 39px;
						
						.nv {
							display: flex;
							justify-content: center;
							align-items: center;
							width: 14px;
							height: 14px;
							background-color: #E03030;
							color: #fff;
						}
						
						.nan {
							display: flex;
							justify-content: center;
							align-items: center;
							width: 14px;
							height: 14px;
							background-color: #2362fb;
							color: #fff;
						}
					}
					
					.intro {
						display: flex;
						justify-content: left;
						align-items: center;
						background-color: #f7f7f7;
						color: #777;
						font-size: 14px;
						
						.intro-icon {
							margin-left: 5px;
							margin-right: 5px;
							padding-top: 1px;
							font-size: 16px;
						}
						
						.intro-title {
							font-weight: bold;
						}
					}
				}
			}
		}
	}
	
	
	/*栅格布局*/
	.bg-purple {
		background: #d3dce6;
	}
	.bg-purple-light {
		background: #e5e9f2;
	}
	.grid-content {
		border-radius: 4px;
		min-height: 36px;
	}
</style>
